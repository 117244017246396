

/* Animation */
@-webkit-keyframes infiniteRotate {    
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes infinite-rotate {  
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}
@-moz-keyframes infinite-rotate {  
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}



@-webkit-keyframes backgroundBlink {
  0%{background-position:73% 0%}
  50%{background-position:28% 100%}
  100%{background-position:73% 0%}
}
@-moz-keyframes backgroundBlink {
  0%{background-position:73% 0%}
  50%{background-position:28% 100%}
  100%{background-position:73% 0%}
}
@keyframes backgroundBlink {
  0%{background-position:73% 0%}
  50%{background-position:28% 100%}
  100%{background-position:73% 0%}
}

@-webkit-keyframes BackgroundFlow {
  0%{background-position:0% 50%}
  50%{background-position:100% 50%}
  100%{background-position:0% 50%}
}
@-moz-keyframes BackgroundFlow {
  0%{background-position:0% 50%}
  50%{background-position:100% 50%}
  100%{background-position:0% 50%}
}
@keyframes BackgroundFlow {
  0%{background-position:0% 100%}
  50%{background-position:100% 0%}
  100%{background-position:0% 100%}
}

.slow-background {
  -webkit-animation: BackgroundFlow 200s ease infinite;
  -moz-animation: BackgroundFlow 200s ease infinite;
  animation: BackgroundFlow 200s ease infinite;
}

.spinnit {

  -webkit-animation: infiniteRotate 2s linear infinite; /* Safari */
  -moz-animation: infiniteRotate 2s linear infinite;
  animation: infiniteRotate 2s linear infinite;
  }
.paused {
  animation-play-state: paused;
}


/* NAVIGATION */
/* Style the navbar */
#navbar {
  z-index: 100000;
}
#logo {
  padding:10px;
  z-index: 10000;
  -webkit-transition: filter 400ms ease-in;
  -moz-transition: filter 400ms ease-in;
  transition: filter 400ms ease-in;

}
.logo:hover {
  filter: blur(0px);
  -webkit-transition: filter 400ms ease-in;
  -moz-transition: filter 400ms ease-in;
  transition: filter 400ms ease-in;

}
.logo {
  filter: blur(20px);
  text-decoration: none;
  position: fixed;
  top:20px;
  left: 50%;
  transform: translateX(-50%);
  width: 300px;
  height: 95px;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 95% 95%;
  -webkit-transition: all 400ms ease-in;
  -moz-transition: all 400ms ease-in;
  transition: all 400ms ease-in;
  box-shadow: none;
  border-radius: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

}
.sticky {
  filter: blur(0px);
  -webkit-transition: filter 400ms ease-in;
  -moz-transition: filter 400ms ease-in;
  transition: filter 400ms ease-in;

  background-repeat: no-repeat;
  background-position: center center;
  background-size: 95% 95%;
  -webkit-transition: all 400ms ease-in;
  -moz-transition: all 400ms ease-in;
  transition: all 400ms ease-in;

 }
 

nav {
  position: absolute;
  width: 300px;

  max-height: 90vh;
  max-width: 90vw;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: _palette(bg);
  border-radius: 20px;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;


  ul {
    margin: auto;

    width: 100%

  }
  li {
    list-style-type: none;
    margin: 10px;
    width: 80%;
    padding: 0;
    a {
      width: 100%;
      display: block;
      margin: auto !important;
      font-size: 14px
    }
  }
 }
 .blurredout {
  filter: blur(5px);
  -webkit-transition: filter 400ms ease-in;
  -moz-transition: filter 400ms ease-in;
  transition: filter 400ms ease-in;
 }

 #wrapper {
  -webkit-transition: filter 400ms ease-in;
  -moz-transition: filter 400ms ease-in;
  transition: filter 400ms ease-in;
 }
.modalDialog {
  position: fixed;
  font-family: Arial, Helvetica, sans-serif;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: _palette(fg-light);
  z-index: 11;
  opacity:0;
  -webkit-transition: opacity 400ms ease-in;
  -moz-transition: opacity 400ms ease-in;
  transition: opacity 400ms ease-in;
  pointer-events: none;
  
}

.modalDialog:target {
  opacity:1;
  pointer-events: auto;
}

.modalDialog > div {
  width: 80%;
  max-width: 1100px;
  max-height: 80vh;
  display: flex;
  align-items: center;
  position: relative;
  margin: 100px auto;
  padding: 20px;
  border-radius: 3px;
  background: rgb(0, 31, 36);
  background: -moz-linear-gradient(#000, rgb(0, 31, 36));
  background: -webkit-linear-gradient(#000, rgb(0, 31, 36));
  background: -o-linear-gradient(#000, rgb(0, 31, 36));
}


.close-button {
  background-color: #FFFFFF;
  color: _palette(accent-dark);
  line-height: 48px;
  position: absolute;
  right: -12px;
  text-align: center;
  top: -10px;
  width: 48px;
  height: 48px;
  text-decoration: none;
  font-weight: bold;
  -webkit-border-radius: 48px;
  -moz-border-radius: 48px;
  border-radius: 48px;
  -moz-box-shadow: 1px 1px 3px #000;
  -webkit-box-shadow: 1px 1px 3px #000;
  box-shadow: 1px 1px 3px #000;
  transition: all 400ms ease-in;
}

.close-button:hover { 
  background-color: _palette(accent); 
  color: #FFFFFF;
}

#closearea {
  position: fixed;
  width: 100%;
  height: 100%;

}

//* POSTS *//

#post-text {
  padding-top: 1rem;
}
//* FOOTER *//

#footer {

  background-color: _palette(fg);
  color: _palette(bg);
  box-shadow: inset 0 10px 10px 0 rgba(0, 45, 107, 0.25) !important;

  h1, h2, h3, h4, h5, p, a, strong {
    color: _palette(bg);
  }
  a {
    text-decoration: none;
  }

  .footflex {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    div {
      text-align: left;
      flex: 0 1 300px;
      max-width: 300px;
      margin: 80px 80px 0 80px;

      ul {
        margin: 0;
        padding: 0;
      }
      li {
        list-style: none;
        border-bottom: 1px solid _palette(bg);
        line-height: 3em;
        margin: 0;
        padding: 0;
      }
    }
  }
  .style2 {
    &:before {
    box-shadow: inset 0 0 0 _size(border-width) _palette(bg);
    }
  }
  .style2:hover {
    &:before {
    box-shadow: inset 0 0 0 _size(border-width) _palette(accent);
    }
  }
}

/* AUTHOR */

#author {
  .bio {
    padding-right: 20px;
    p {
      color: _palette(fg);
    }
  }

}

/* Content images */
div.content {
  img {
    width: 100%;
    height: auto;
    max-width: 500px;
  }
}

/* Cloudcannon editor links */

.editor-link {
  display: none;
 margin-top: 0;
 padding-top: 30px;
 .btn {
   border: 0;
   border-radius: 2px;
   width: 100%;
   max-width: 500px;
   box-sizing: border-box;
   text-decoration: none;
   padding: 10px 15px;
   margin: 0;
   font-size: 18px;
   cursor: pointer;
   background-color: #f7e064;
   color: #333;
   box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.2);

   &:hover {
     background-color: #f4d525;
     color: #333;
   }
 }
}

.cms-editor-active .editor-link {
  display: block;
}

/* cookies */

#cookie-notice {
  padding: 15px;
  display: none;
  text-align: right;
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: _palette(accent-dark);
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-content: center;
  align-items: center;
  z-index: 1000;

  p {
    flex: 1 0 250px;
    margin: 0;
    font-size: 12px;
    font-family: _font(family-fixed);
  }
  @media only screen and (max-width: 569px) {
    p {
      text-align: center;
    }
  }
}

.yes {
  flex: 0 0 250px;

  -moz-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  -webkit-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  -ms-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  border-radius: 0.375em;
  border: 0;
  color: #000 !important;
  background-position-x: 0%;
  background-position-y: 0%;
  background-repeat: repeat;
  background-attachment: scroll;
  background-image: linear-gradient(228deg, _palette(bg), _palette(fg));
  background-origin: padding-box;
  background-clip: border-box;

  background-size: 400% 400%;
  -webkit-animation: backgroundBlink 2s ease infinite;
  -moz-animation: backgroundBlink 2s ease infinite;
  animation: backgroundBlink 2s ease infinite;
  margin: 20px;

}
.yes:hover {
  color: #000 !important;
}

/* Background Video */
.fullscreen-bg {
  position: absolute;
  height: 100%;
  width: 100%;
  overflow: hidden;
  z-index: -100;
  background: #fff;
}

.fullscreen-bg__video {
  background: #fff;
  position: relative;
  left: 50%;
  top:50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

/* Post Navigation */

.post-nav {
  /* Insert your custom styling here. Example:

     font-size: 14px;
     margin-bottom: 1em;
  */
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

}
.post-nav div {
  /* flex-grow, flex-shrink, flex-basis */
  flex: 0 1 300px;

}
.post-nav-next {
  text-align: right;
}


/* Stream links */ 
.streambox {
 
    display: block;
    text-align: center;

  .streamer {
    list-style-type:none;
    display:flex;
    justify-content: center;
    flex-wrap: wrap;

    a {
      flex: 1 0 150px;
      margin:10px;
    }
    .button {
      display: block;
      flex: unset;
    }
    h3 {
      flex: unset;
    }
  }
}

