///
/// Story by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Image */

	.image {
		border: 0;
		border-radius: _size(border-radius);
		display: inline-block;
		position: relative;

		img {
			display: block;
			border-radius: _size(border-radius);
		}

		&.left,
		&.right {
			width: 40%;
			max-width: 10rem;

			img {
				width: 100%;
			}
		}

		&.left {
			float: left;
			margin: 0 1.5rem 1rem 0;
			top: 0.25rem;
		}

		&.right {
			float: right;
			margin: 0 0 1rem 1.5rem;
			top: 0.25rem;
		}

		&.fit {
			display: block;
			margin: 0 0 _size(element-margin) 0;
			width: 100%;

			img {
				width: 100%;
			}
		}

		&.main {
			display: block;
			margin: 0 0 (_size(element-margin) * 1.5) 0;
			width: 100%;

			img {
				width: 100%;
			}
		}
	}