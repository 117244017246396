///
/// Story by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Table */

	.table-wrapper {
		-webkit-overflow-scrolling: touch;
		margin: 0 0 _size(element-margin) 0;
		overflow-x: auto;

		> table {
			margin-bottom: 0;
		}
	}

	table {
		margin: 0 0 _size(element-margin) 0;
		width: 100%;

		tbody {
			tr {
				border: solid _size(border-width);
				border-left: 0;
				border-right: 0;
			}
		}

		td {
			padding: 0.75rem 0.75rem;
		}

		th {
			font-size: 0.9rem;
			font-weight: _font(weight-bold);
			padding: 0 0.75rem 0.75rem 0.75rem;
			text-align: left;
		}

		thead {
			border-bottom: solid (_size(border-width) * 2);
		}

		tfoot {
			border-top: solid (_size(border-width) * 2);
		}

		&.alt {
			border-collapse: separate;

			tbody {
				tr {
					td {
						border: solid _size(border-width);
						border-left-width: 0;
						border-top-width: 0;

						&:first-child {
							border-left-width: _size(border-width);
						}
					}

					&:first-child {
						td {
							border-top-width: _size(border-width);
						}
					}
				}
			}

			thead {
				border-bottom: 0;
			}

			tfoot {
				border-top: 0;
			}
		}

		&.fixed {
			table-layout: fixed;
		}
	}

	@mixin color-table($p: null) {
		table {
			tbody {
				tr {
					border-color: _palette($p, border);

					&:nth-child(2n + 1) {
						background-color: _palette($p, border-bg);
					}

					&.alt {
						background-color: _palette($p, border-bg) !important;
					}
				}
			}

			th {
				color: _palette($p, fg-bold);
			}

			thead {
				border-bottom-color: _palette($p, border);
			}

			tfoot {
				border-top-color: _palette($p, border);
			}

			&.alt {
				tbody {
					tr {
						td {
							border-color: _palette($p, border);
						}
					}
				}
			}

			&.uniform {
				tbody {
					tr {
						&:nth-child(2n + 1) {
							background-color: transparent;
						}
					}
				}
			}
		}
	}

	@include color-table;