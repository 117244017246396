///
/// Story by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Icon */

	.icon {
		@include icon;
		border-bottom: none;
		position: relative;
		text-align: center;

		> .label {
			display: none;
		}

		&:before {
			line-height: inherit;
		}

		&.solid {
			&:before {
				font-weight: 900;
			}
		}

		&.brands {
			&:before {
				font-family: 'Font Awesome 5 Brands';
			}
		}

		&.style2 {
			&:before {
				border-radius: 2.75em;
				display: inline-block;
				height: 2.75em;
				line-height: 2.75em;
				width: 2.75em;
			}
		}

		&.major {
			display: block;
			margin: 0 0 (_size(element-margin) * 0.5) 0;

			&:before {
				font-size: 1.25rem;
			}
		}
	}

	a.icon {
		&.style2 {
			&:before {
				@include vendor('transition', (
					'background-color #{_duration(transition)} ease-in-out',
					'box-shadow #{_duration(transition)} ease-in-out',
					'color #{_duration(transition)} ease-in-out'
				));
			}
		}
	}

	@mixin color-icon($p: null) {
 		.icon {
 			&.style2 {
 				&:before {
					box-shadow: inset 0 0 0 _size(border-width) _palette($p, border);
 				}
 			}
 		}
     .player {
      &.style2 {
        &:before {
         box-shadow: inset 0 0 0 _size(border-width) _palette($p, fg-light);
        }
      }
    }

		a.icon {
			&.style2 {
				&:hover {
					&:before {
						box-shadow: inset 0 0 0 _size(border-width) _palette($p, accent);
						color: _palette($p, accent);
					}
				}

				&:active {
					&:before {
						background-color: transparentize(_palette($p, accent), 0.9);
						box-shadow: inset 0 0 0 _size(border-width) _palette($p, accent);
						color: _palette($p, accent);
					}
				}
			}
		}
	}

	@include color-icon;