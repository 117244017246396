/* Audio Player Styles
================================================== */

/* Default / Desktop / Firefox */

audio {
  margin: 0;
  width: 100%;
}

#mainwrap {
  border-radius: 5px;
  width: 100%;
  ul {
    list-style: none;
    padding: 0;
    li:last-child {
      border-radius: 0 0 5px 5px;
    }
  }
}

#audiowrap {
  background-color: _palette(accent-dark);
  margin: 0 auto 0 auto;
}

#plwrap {
  margin: 0 auto;
}

#tracks {
  min-height: 65px;
  position: relative;
  text-align: center;
  text-decoration: none;

}


#nowPlay {
  display: flex;
  width: 100%;
  height: 60px;
  flex-wrap: unset;
  justify-content: center;
  align-items: center;
  background-color: _palette(accent-dark);
  border-radius: 5px 5px 0 0;
}

#npTitle {
  margin: 0;
  padding: 21px;

}

#npAction {
  display: none;
  margin: 0;
  padding: 21px;
  font-size: 12px;
}

#plList {
  margin: 0;
}

#plList li {
  background-color: _palette(border-darken);
  cursor: pointer;
  margin: 0;
  padding: 21px 0;
  border: 1px solid _palette(accent-dark);
  border-top: 0;
  transition: all 400ms ease-in-out;
}

#plList li:hover {
  background-color: _palette(border-alt);
  transition: all 400ms ease-in-out;
}

.plItem {
  position: relative;
}

.plTitle {
  left: 75px;
  overflow: hidden;
  position: absolute;
  right: 65px;
  text-overflow: ellipsis;
  top: 0;
  white-space: nowrap;
  font-weight: bold;
  padding-right: 10px;
}

.plNum {
  padding-left: 21px;
  width: 65px;
}

.plLength {
  padding-left: 21px;
  position: absolute;
  right: 21px;
  top: 0;
}

.plSel, .plSel:hover {
  background-color: _palette(border-alt)!important;
  cursor: default!important;
  border-radius: 5px;

}

a[id^="btn"] {
  background-color: _palette(border-darken);
  color: _palette(fg);
  cursor: pointer;
  margin: 0;
  padding: 0 27px 0 21px;
  text-decoration: none;
}


a[id^="btn"]::-moz-focus-inner {
  border: 0;
  padding: 0;
}

#noJSalbum {
  display: none;
}