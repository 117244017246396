///
/// Story by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Button */

	input[type="submit"],
	input[type="reset"],
	input[type="button"],
	button,
	.button {
		@include vendor('appearance', 'none');
		@include vendor('transition', (
			'background-color #{_duration(transition)} ease-in-out',
			'box-shadow #{_duration(transition)} ease-in-out',
			'color #{_duration(transition)} ease-in-out'
		));
		border: 0;
		cursor: pointer;
		display: inline-block;
    font-weight: _font(weight-bold);
    font-family: _font(family-fixed);
		letter-spacing: _font(kerning-alt);
		text-align: center;
		text-decoration: none;
		text-transform: uppercase;
		white-space: nowrap;
		font-size: 0.75rem;
		max-width: 20rem;
		height: 3.75em;
		line-height: 3.75em;
		border-radius: 7px;
		padding: 0 2.5em;
		text-overflow: ellipsis;
		overflow: hidden;

		&.icon {
			&:before {
				margin-right: 0.5rem;
			}
		}

		&.fit {
			width: 100%;
		}

		&.small {
			font-size: 0.7rem;
			height: 3.325em;
			line-height: 3.325em;
			border-radius: 7px;
			padding: 0 2em;
    }
    &.smaller {
			font-size: 0.5rem;
			height: 2.75em;
			line-height: 2.75em;
			border-radius: 7px;
			padding: 0 2em;
		}

		&.large {
			font-size: 0.8rem;
			height: 4em;
			line-height: 4em;
			border-radius: 7px;
			padding: 0 3em;
		}

		&.wide {
			min-width: 14em;
		}

		&.disabled,
		&:disabled {
			@include vendor('pointer-events', 'none');
			opacity: 0.25;
		}
	}

	@mixin color-button($p: null) {
		input[type="submit"],
		input[type="reset"],
		input[type="button"],
		button,
		.button {
			background-color: _palette(fg);
			box-shadow: inset 0 0 5px _size(border-width) _palette($p, border-darken);
			color: _palette($p, bg) !important;

			&:hover {
				box-shadow: inset 0 0 5px _size(border-width) _palette($p, border);
        color: _palette($p, fg) !important;
        background-color: _palette(border-darken);
			}

			&:active {
				background-color: transparentize(_palette($p, accent), 0.8);
				box-shadow: inset 0 0 0 _size(border-width) _palette($p, accent);
				color: _palette($p, accent) !important;
			}

			&.primary {
				background-color: _palette($p, fg-bold);
				box-shadow: none;
				color: _palette($p, bg) !important;

				&:hover {
					background-color: _palette($p, accent);
				}

				&:active {
					background-color: darken(_palette($p, accent), 12);
				}
			}
		}
	}

	@include color-button;